<template>
  <section class="py-8">
    <div id="services" class="container text-center">
      <label class="text-sm font-bold mb-6 underline mb-4 text-slate-300">SERVICES</label>
      <div class="flex flex-col mt-5 space-y-8 md:space-y-10">
        <!-- feature 1 -->
        <div class="p-1 shadow rounded flex flex-col md:flex-row justify-between text-white">
          <div class="flex flex-col p-1 space-y-5 md:p-4 md:space-y-8">
            <h3 class="text-3xl md:text-4xl font-bold mb-2 font-sans md:text-start">1. Scrappez depuis plusieurs plateformes</h3>
            <p class="font-sans text-xl md:text-3xl md:text-start font-sans font-thin">Ne vous limitez plus à Aliexpress. 
              <br/>Avec <strong class="font-sans font-bold italic">Realviews</strong>, importez des avis depuis différentes plateformes. 
              <br/><br/>Vous pouvez importez des avis depuis un Amazon ou encore 
              depuis <strong class="underline">un site shopify</strong> <strong class="text-custom-purple-button font-bold underline">en un clic.</strong></p>
          </div>
          <img class="w-full md:w-auto md:max-w-[860px] md:max-h-[515px]" src="../assets/scrap-provider-screen.png"/>
        </div>

        <!-- feature 2 -->
        <div class="p-1 shadow rounded flex flex-col md:flex-row md:justify-between text-white space-y-3">
          <div class="flex flex-col p-1 space-y-5 md:p-4 md:space-y-8">
            <h3 class="text-3xl md:text-4xl font-sans font-bold mb-2 md:text-start">OU générez des avis en un clic.</h3>
            <p class="font-sans text-xl md:text-3xl md:text-start font-thin">Vous lancez un nouveau produit et souhaitez simuler des avis (à titre éducatif).
              Grâce à l'intelligence artificielle, Realviews pourra générer autant d'avis que vous le souhaitez 
              à partir d'un lien Amazon / Aliexpress ou d'une description 
              <strong class="underline text-custom-purple-button">en un clic.</strong></p>
          </div>
          <img class="w-full md:w-auto md:max-w-[860px] md:max-h-[515px]" src="../assets/generer-screen.png"/>
        </div>

        <!-- feature 3 -->
        <div class="p-1 shadow rounded flex flex-col md:flex-row justify-between text-white">
          <div class="flex flex-col p-1 space-y-5 md:p-4 md:space-y-8">
            <h3 class="text-3xl md:text-4xl font-sans font-bold mb-2 md:text-start">2. Selectionnez vos critères</h3>
            <p class="font-sans text-xl md:text-3xl font-thin md:text-start">Mettez le nombre d'avis que vous souhaitez, 
              Selectionnez la langue que vous désirez et laissez 
              <strong class="text-custom-purple-button font-bold underline">la magie opérer.</strong></p>
          </div>
          <img class="w-full md:w-auto md:max-w-[860px] md:max-h-[515px]" src="../assets/scrap-form-screen.png"/>
        </div>
  
        <!-- feature 4 -->
        <div class="p-1 shadow rounded flex flex-col md:flex-row justify-between text-white text-center md:space-x-4">
          <div class="flex flex-col p-1 space-y-5 md:p-4 md:space-y-8">
            <h3 class="text-3xl md:text-4xl font-sans font-bold mb-2 text-center whitespace-normal md:text-start">3. <br class="md:hidden"/>Importez vos avis sur Shopify instantanément</h3>
            <!-- <h3 class="text-5xl font-bold mb-2 text-center md:text-start">3. Importez vos avis sur Shopify instantanément</h3> -->
            <p class="font-sans text-xl md:text-3xl font-thin md:text-start">Les membres de 
              <strong class="font-sans font-bold italic">Realviews</strong> 
              pourront télécharger une application shopify gratuite pour héberger leur avis.<br/><br/> 
            <strong class="text-custom-purple-button font-bold underline">Plus besoin de payer</strong> des apps pour héberger vos avis.</p>
          </div>
          <img class="w-full md:w-auto md:max-w-[860px] md:max-h-[515px]" src="../assets/product-screen.png"/>
        </div>      
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesComponent',
};
</script>
