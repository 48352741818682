<template>
    <div class="flex items-center justify-center h-64 md:h-screen p-3">
        <iframe 
        class="w-full h-full md:max-w-[1200px] md:max-h-[675px]"
        src="https://www.youtube.com/embed/i4in66EBF9Y?si=pAG1q-7zxUmyzSP4" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
</template>

<script>
export default {
    name: 'YoutubeVideo',
    // Add your component's properties, methods, and lifecycle hooks here
}
</script>

<style scoped>
/* Add your component's CSS styles here */
</style>