<template>
  <header id="home" class="flex justify-around text-white p-3 ">
    <div class="container mx-auto flex justify-between items-center">
      <div id="logo_sidebar" class="logo size-52 mt-5 hover:focus">
        <img
          src="../assets/logo-blanc.png"
          class="object-cover h-full w-full"
          @click="goToHomePage" />
      </div>
      <nav>
        <ul class="flex space-x-4">
          <li v-if="this.$route.path !== '/'" class="transition ease-in-out delay-50 
          hover:-translate-y-1 hover:scale-110 duration-300 ...">
          <a href="#" @click.prevent="goToHomePage" class="text-white font-bold hover:text-purple-500 text-lg font-sans">Accueil</a></li>

          <li v-if="this.$route.path === '/'" 
          class="transition ease-in-out delay-50 
          hover:-translate-y-1 hover:scale-110 duration-300 ...">
            <a href="#" @click.prevent="scrollTo('#services')" class="text-white font-bold text-lg font-sans hover:text-purple-500">Services</a>
          </li>
          <li v-if="this.$route.path === '/'" class="transition ease-in-out delay-50 
          hover:-translate-y-1 hover:scale-110 duration-300 ...">
          <a href="#" @click.prevent="scrollTo('#footer-menu')" class="text-white hover:text-purple-500 text-lg font-bold font-sans">Contact</a></li>
        </ul>
      </nav>

      <button class="bg-custom-purple-button text-white text-sm py-3 px-8 font-bold rounded-full font-sans 
      hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50"
      @click="openSubscriptionPage">
      ESSAYER GRATUITEMENT</button>
    </div>
  </header>
</template>

<script>
import VueScrollTo from 'vue-scrollto';

export default {
  name: 'HeaderComponent',

  data() {
    return {
      // Add your data here
    }
  },

  methods: {
    // Add your methods here
    scrollTo(section) {
      VueScrollTo.scrollTo(section, 700);
    },
    openSubscriptionPage() {
      window.open('https://realviews-subscribe.pages.dev', '_blank');
    },
    goToHomePage() {
      this.$router.push('/');
    }
  },
};
</script>
