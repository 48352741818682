<template>
  <header id="home" class="flex text-white p-1 relative" ref="header">
    <div class="container mx-auto flex justify-between items-center lg:hidden">
      <button @click="isMenuOpen = !isMenuOpen" class="justify-start">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-7 w-7">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
      <div id="logo_sidebar" class="logo size-36 mt-2 mx-auto">
        <img src="../assets/logo-blanc.png" class="object-cover h-full w-full" @click="goToHomePage" />
      </div>
    </div>
    <div class="container mx-auto flex justify-between items-center hidden lg:flex">
      <!-- The rest of your existing header code goes here -->
    </div>
    <transition name="slide-down">
      <div v-if="isMenuOpen" class="absolute top-full left-0 w-full bg-white z-50 p-5 lg:hidden">
        <nav>
          <ul class="flex flex-col space-y-4">
            <li v-if="this.$route.path !== '/'"><a href="#" @click.prevent="goToHomePage" class="text-black font-bold text-lg font-sans">Accueil</a></li>
            <li v-if="this.$route.path === '/'"><a href="#" @click.prevent="scrollTo('#services')" class="text-black font-bold text-lg font-sans">Services</a></li>
            <li v-if="this.$route.path === '/'"><a href="#" @click.prevent="scrollTo('#footer-menu')" class="text-black font-bold text-lg font-sans">Contact</a></li>
            <button class="bg-custom-purple-button text-white p-2 px-3 font-bold font-sans rounded-xl mb-5 w-content mx-auto" @click="subscription_redirect">Essayer</button>
          </ul>
        </nav>
      </div>
    </transition>
  </header>
</template>

<script>
import VueScrollTo from 'vue-scrollto';

export default {
  name: 'HeaderComponent',

  data() {
    return {
      isMenuOpen: false,
      headerHeight: 0,
    }
  },

  mounted() {
    this.headerHeight = this.$refs.header.offsetHeight;
  },

  methods: {
    scrollTo(section) {
      VueScrollTo.scrollTo(section, 700);
      this.isMenuOpen = false;
    },
    openSubscriptionPage() {
      window.open('https://realviews-subscribe.pages.dev', '_blank');
    },
    goToHomePage() {
      this.$router.push('/');
    },
    subscription_redirect() {
      window.open('https://realviews-subscribe.pages.dev', '_blank');
      this.isMenuOpen = false;
    },
  },
}
</script>

<style scoped>
.slide-down-enter-active, .slide-down-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-down-enter, .slide-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-down-enter-to, .slide-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}
</style>
