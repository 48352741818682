<template>
  <footer id="contact" class="text-white p-4 mt-5 mb-8">

    <!-- <CtaFooter /> -->

    <div class="p-2 md:p-0">
      <CtaFooter />
    </div>

    <!-- Menu Navigation -->
    <div class="flex justify-around items-center md:flex-row flex-col">
      <div class="flex">
        <img src="../assets/logo-blanc.png" class="w-48" />
      </div>

      <div id="footer-menu" class="flex flex-col text-center space-y-8 md:flex-row md:space-x-8 md:space-y-0">
        <div class="flex flex-col mx-auto md:text-start">
          <h2 class="font-bold mb-2">NAVIGATION</h2>
          <nav class="flex flex-col justify-center space-y-4">
            <ul>
              <li><a href="#" @click.prevent="scrollTo('#home')" class="hover:text-accent hover:bg-slate-500">Acceuil</a></li>
              <li><a href="#" @click.prevent="scrollTo('#services')" class="hover:text-accent hover:bg-slate-500">Services</a></li>
              <!-- <li><a href="#" class="hover:text-accent">Pricing</a></li> -->
              <li>
                <router-link to="/contact" @click="navigateToContact"> 
                  <a href="#" class="hover:text-accent hover:bg-slate-500">Contactez-nous</a>
                </router-link>
            </li>
            </ul>
          </nav>
        </div>

        <div class="flex flex-col mx-auto md:text-start">
          <h2 class="font-bold mb-2">INFORMATIONS</h2>
          <nav class="flex flex-col justify-center space-y-4">
            <ul>
              <li>
                <router-link to="/term-of-service" @click="navigateToTermOfService">
                  <a href="#" class="hover:text-accent hover:bg-slate-500">Conditions d'utilisation</a>
                </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy" @click="navigateToPrivacyPolicy">
                  <a href="#" class="hover:text-accent hover:bg-slate-500">Politique de confidentialite</a>
                </router-link>
                </li>
            </ul>
          </nav>
        </div>
      </div>

      <!-- 
      <div class="flex justify-center space-x-4 p-5">
        <a href="mailto:ufghr
        @realviews.com" class="hover:text-accent">
          <img src="../assets/openai-2.svg" class="w-8" />
        </a>
        <a href="https://twitter.com/RealViews" class="hover:text-accent">
          <img src="../assets/openai-2.svg" class="w-8" />
        </a>
        <a href="https://www.linkedin.com/company/realviews" class="hover:text-accent">
          <img src="../assets/openai-2.svg" class="w-8" />
        </a>
      </div> -->
    </div>


    <div class="container mx-auto text-center mt-10 flex flex-col md:flex-row justify-center space-x-1">
      <p>Copyright &copy; 2024 Realviews by </p> 
      <p class=" transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 ..."> 
          <a href="https://calendly.com/mehdi-techwork/consultation-gratuite-solutions-web-et-mobile"
        class=" text-purple-500 font-bold underline"
        target="_blank">TechWork</a>
      </p>
    </div>
  </footer>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import CtaFooter from './CtaFooter.vue';

export default {
  name: 'FooterComponent',

  components: {
    CtaFooter,
  },

  methods: {
    scrollTo(section) {
      VueScrollTo.scrollTo(section, 700);
    },
    navigateToContact() {
      this.$router.push('/contact').then(() => {
        window.scrollTo(0, 0);
      });
    },
    navigateToTermOfService() {
      this.$router.push('/term-of-service').then(() => {
        window.scrollTo(0, 0);
      });
    },
    navigateToPrivacyPolicy() {
      this.$router.push('/privacy-policy').then(() => {
        window.scrollTo(0, 0);
      });
    }

  },
};
</script>
