<template>
    <div class="min-h-screen flex flex-col bg-custom-purple">
        <Header class="hidden lg:block"/>
        <HeaderMobile class="block lg:hidden"/>
        <Hero />
        <YoutubeVideo class="flex justify-center items-center"/>
        <Features />
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import Hero from '../components/Hero.vue';
import Features from '../components/Features.vue';
import Footer from '../components/Footer.vue';
import YoutubeVideo from '../components/YoutubeVideo.vue';
import HeaderMobile from '../components/HeaderMobile.vue';

export default {
    name: 'HomeVue',
    components: {
        Header,
        HeaderMobile,
        Hero,
        YoutubeVideo,
        Features,
        Footer
    },
    data() {
        return {
            // Add your data here
        }
    },
    methods: {
        // Add your methods here
    },
    mounted() {
        // Code executed when the component is mounted
    }
}
</script>

<style scoped>
/* Add your CSS here */
</style>