<template>
  <section class="text-white p-6 md:p-8">
    <div class="flex flex-col items-center justify-center text-center">
      <div class="flex space-x-2">
        <p class="text-xl text-slate-200 mb-4">Une technologie</p>
        <img src="../assets/openai-2.svg" class="w-8 h-8 bg-white rounded-full mb-3" />
        <p class="text-xl text-slate-200 mb-4">OpenAI</p>
      </div>
      <h2 class="text-4xl md:text-7xl mb-4 mt-3">Donner du Trust à votre boutique en un<br/> clic avec RealViews</h2>
      <p class="text-lg md:text-xl text-slate-400 italic mb-8 font-sans">Scrappez et générez des avis depuis n'importe quelle plateforme instantanément grâce à <br/> l'intelligence artificielle.</p>
      <button class="bg-custom-purple-button text-white text-sm p-3 px-7 font-bold 
      font-sans 
      hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50
      rounded-full mb-5"
      @click="subscription_redirect">ESSAI GRATUIT</button>
      <div class="flex justify-center mt-8 md:w-1/3 w-full md:mb-8 mb-4">
        <img src="../assets/hero-image-v2.jpeg" class="w-145"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroComponent',

  data() {
    return {
      // Add your data here
    }
  },

  methods: {
    // Add your methods here
    subscription_redirect() {
      window.open('https://realviews-subscribe.pages.dev', '_blank');
    }
  },
};
</script>
