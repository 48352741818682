<template>
    <!-- CTA inscrivez-vous -->
    <div class="container bg-white text-center text-black 
    justify-center items-center rounded-xl mb-2 mt-20 p-8 
    flex flex-col outline-black border-3 border-black rounded-2xl 
    max-w-[1000px] 
    md:min-w-[1000px] 
    md:max-w-[1000px] 
    md:min-h-[369px]
    md:pl-20 md:pr-20    
    pl-5 pr-5">
      <div class="flex flex-col justify-center p-8 space-y-5">
        <h2 class="font-bold mb-2 text-center text-2xl md:text-4xl font-sans">Inscrivez-vous <br/> maintenant</h2>
        <p class="paragraph italic text-lg">Pour obtenir votre accés et profiter de votre période d'essai</p>
        <button class="bg-custom-purple-button text-white p-3 px-4 font-bold font-sans rounded-xl mb-5 w-content mx-auto font-sans"
        @click="subscription_redirect">Je m'inscris</button>
      </div>
    </div>
</template>

<script>
export default {
  // Your JavaScript code goes here
    methods: {
        // Add your methods here
        subscription_redirect() {
        window.open('https://realviews-subscribe.pages.dev', '_blank');
        }
    },
}
</script>

<style>
/* Your CSS code goes here */
</style>